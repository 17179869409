export type TablePagination = { 
    basicInfo: PaginationBasicInfo ,
link:PaginationLinks}
    
export interface PaginationBasicInfo  {
    total: number,
    per_page: number,
    current_page: number,
    last_page:  number,
    from:  number,
    to: number,
}

export interface PaginationLinks  {
    next: string | null,
prev: string | null,
    last: string,
    first: string
}



const linkInitialData:PaginationLinks = {
    next:"",
    prev:"",
    last:"",
    first:"",
}

const basicInfoInitialData :PaginationBasicInfo = {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page:  0,
    from:  0,
    to: 0,
}

export const PaginationInitialData:TablePagination = {
    link:linkInitialData,
    basicInfo:basicInfoInitialData
  
  };