

import { createSlice } from "@reduxjs/toolkit";
import { PaginationInitialData } from "types/Pagination";
import { subjectInitialData, subjectInitialDataArray } from "types/Subject";
import { userInitialDataArray } from "types/User";

const initialState = {
    subject: {
    is_initial_data:true,
    subjects:subjectInitialDataArray,
    selectedSubject:subjectInitialData,
    unenrolledStudent:userInitialDataArray,
    tablePagination:PaginationInitialData
  },
};

export const SubjectSlice  = createSlice({
  name: "SubjectData",
  initialState,
  reducers: {
    setSubjects: (state, action) => {
      state.subject.is_initial_data = false;
      state.subject.subjects = action.payload;
    },
    setSelectedSubject: (state, action) => {
      state.subject.selectedSubject = action.payload;
    },
    setUnenrolledStudent: (state, action) => {
      state.subject.unenrolledStudent = action.payload;
    },
    setTablePagination: (state, action) => {
      state.subject.is_initial_data = false;
      state.subject.tablePagination = action.payload;
    },
  },
});

export const { setSubjects,setSelectedSubject ,setUnenrolledStudent,setTablePagination} =
SubjectSlice.actions;
export default SubjectSlice.reducer;