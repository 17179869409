import { getLang } from "../../services/localStorage-service";
import { createSlice } from "@reduxjs/toolkit";
import { userInitialDataArray,userInitialData } from "types/User";
import { PaginationInitialData } from "types/Pagination";

const initialState = {
    admin: {
    is_initial_data:true,
    admins:userInitialDataArray,
    selectedAdmin:userInitialData,
    paginationData:PaginationInitialData
  },
};

export const AdminSlice  = createSlice({
  name: "AdminData",
  initialState,
  reducers: {
    storeAdmins: (state, action) => {
      state.admin.is_initial_data = false;
      state.admin.admins = action.payload;
    },
    storeSelectedAdmin: (state, action) => {
      state.admin.is_initial_data = false;
      state.admin.selectedAdmin = action.payload;
    },
    storePagination: (state, action) => {
      state.admin.is_initial_data = false;
      state.admin.paginationData = action.payload;
    }
  },
});

export const { storeAdmins,storeSelectedAdmin,storePagination } =
AdminSlice.actions;
export default AdminSlice.reducer;