import { getLang, getToken,getSchoolId } from "../services/localStorage-service";

import axios from "axios";


export const AdminPortalHttpService = () => axios.create({
    baseURL:process.env.REACT_APP_ADMIN_PORTAL_BACKEND_URL,
    headers:{
        Accept:"application/json",
         Authorization: getToken() != null && `Bearer ${getToken()}` ,
        "X-Content-Lang": getLang(),
        "Access-Control-Allow-Origin": "*",
        "ngrok-skip-browser-warning":true
    }
});


export const ClientPortalHttpService = () => axios.create({
    baseURL:process.env.REACT_APP_CLIENT_PORTAL_BACKEND_URL,
    headers:{
        Accept:"application/json",
         Authorization: getToken() != null && `Bearer ${getToken()}` ,
        "X-Content-Lang": getLang(),
        "X-Tenant-ID": getSchoolId(),
        "Access-Control-Allow-Origin": "*",
        "ngrok-skip-browser-warning":true
    }
});

