import React from "react";
import { useTranslation } from "react-i18next";
import pageNotFoundIcon from "assets/images/PageNotFoundIcon.svg";
import { IoMdArrowBack } from "react-icons/io";
import { getLang } from "services/localStorage-service";
import { getLangDir } from "helpers/get-lang-dir";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const { t } = useTranslation();
  const staticData: any = t("pages.not_found_error_page");
  const navigate = useNavigate();
  const handelOnClickBack = () => {
    window.history.back();
  };
  const handelOnClickDashboard = () => {
    navigate("/dashboard");
  };
  return (
    <div
      className=" h-screen  w-screen flex justify-center items-center"
      style={{
        fontFamily: `${getLang() === "ar" ? "Cairo" : "Kumbh Sans"}`,
        direction: getLangDir(),
      }}
    >
      <div className="grid grid-cols-12  w-full h-full place-items-center  ">
        <div className=" col-span-6 mobile:col-span-12  ">
          <img src={pageNotFoundIcon} className="w-[600px] " />
        </div>
        <div className=" col-span-6 mobile:col-span-12 text-start w-full p-2 ">
          <p className=" text-primary font-bold text-lg">
            {staticData.error_code}
          </p>
          <h1 className=" font-bold  text-6xl mobile:text-4xl tablet:text-4xl sm_tablet:text-3xl">
            {staticData.title}
          </h1>
          <h6 className=" my-3  text-xl">{staticData.description}</h6>
          <div className="flex my-3 justify-start items-center">
            <button
              className="bg-primary mx-2  rounded p-2 text-white"
              onClick={handelOnClickDashboard}
            >
              {staticData.actions.dashboard_button}
            </button>
            <button
              className="bg-transparent border rounded p-2 flex justify-center items-center"
              style={{ direction: "ltr" }}
              onClick={handelOnClickBack}
            >
              <IoMdArrowBack />
              <p className="mx-2">{staticData.actions.back_button}</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
