import React, { Suspense, useEffect } from "react";
import { RouterProvider } from "react-router-dom";

import Pusher from "pusher-js";
import "./App.css";
import Preloader from "./components/global/Preloader";
import { QueryClient, QueryClientProvider } from "react-query";
import useRoutes from "./routes";
import  { Toaster } from "react-hot-toast";
import { getLangDir } from "helpers/get-lang-dir";
import { getLang } from "services/localStorage-service";

function App() {
  // useEffect(() => {
  //   const pusher = new Pusher("ed03efd6a80bfc1df0e8", {
  //     cluster: "ap2",
  //   });
  //   const channel1 = pusher.subscribe("my-channel");
  //   // You can bind more channels here like this
  //   // const channel2 = pusher.subscribe('channel_name2')
  //   channel1.bind("private_channel_id", function (data: any) {
    
  //   //  console.log(data);
      

  //     // Code that runs when channel1 listens to a new message
  //   });

  //   return () => {
  //     pusher.unsubscribe("my-channel");
  //     // pusher.unsubscribe('channel_name2')
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: ( 1000 * 60 * 60 * 24),
      },
    },
  });

  const router = useRoutes();
  return (
    <Suspense fallback={<Preloader />}>
       <QueryClientProvider client={queryClient}>
       <RouterProvider router={router} />
      <Toaster position={getLang() == "ar"? "top-left" :"top-right"} reverseOrder={true} containerStyle={{direction:getLangDir()}}  />
       </QueryClientProvider>

    </Suspense>
  );
}

export default App;
