export type OTP = {
    type:string,
    token: string,
    expires_at:string,
    userEmail: string,
    securedUserEmail:string,
}


export const otpInitialData:OTP = {
    type:"",
    token: "",
    expires_at: "",
    userEmail: "",
    securedUserEmail:""
}