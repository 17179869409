

import { createSlice } from "@reduxjs/toolkit";
import { lessonInitialData, lessonInitialDataArray } from "types/Lesson";
import { PaginationInitialData } from "types/Pagination";


const initialState = {
    lesson: {
    is_initial_data:true,
    lesson:lessonInitialDataArray,
    selectedLesson:lessonInitialData,
    tablePagination:PaginationInitialData
  },
};

export const LessonSlice  = createSlice({
  name: "LessonData",
  initialState,
  reducers: {
    storeLessons: (state, action) => {
      state.lesson.is_initial_data = false;
      state.lesson.lesson = action.payload;
    },
    storeSelectedLesson: (state, action) => {
      state.lesson.is_initial_data = false;
      state.lesson.selectedLesson = action.payload;
    },
    setTablePagination: (state, action) => {
      state.lesson.is_initial_data = false;
      state.lesson.tablePagination = action.payload;
    },

  },
});

export const { storeLessons,storeSelectedLesson,setTablePagination } =
LessonSlice.actions;
export default LessonSlice.reducer;