import { getLang } from "../../services/localStorage-service";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    appSettings: {
    lang:getLang(),
    tenant_id:""
  },
};

export const AppSettingsSlice  = createSlice({
  name: "AppSettings",
  initialState,
  reducers: {
    setLocalLang: (state, action) => {
      state.appSettings.lang = action.payload;
    },
    setTenant: (state, action) => {
      state.appSettings.tenant_id = action.payload;
    }
  },
});

export const { setLocalLang,setTenant } =
AppSettingsSlice.actions;
export default AppSettingsSlice.reducer;