export const components: any = {
  side_bar: {
    dashboard: "Dashboard",
    teacher: "Teachers",
    students: "Students",
    admins:"Admins",
    classes: "Classes",
    billing: "Billing",
    settings_and_profile: "Settings / profile",
    exam: "Exams",
    message:"Messages",
    subject:"Subjects",
  },
  subject_card: {
    exams: "Exams",
    lessons: "Lessons",
    assignments: "Assignments",
    students_administration: "Student administration",
    grade_administration:"Grade administration",
    edit_subject:"Edit",
    delete_subject:"Delete",
    enroll_student:"Enroll Student",
    students:"Student"
  },

  element_playground:{
    question_type:"Question Type",
    grade:"Grade",
    correct_answer:"Correct Answer",
    question_placeholder:"write you question here ...",
    actions:{
      add_question:"Add Question",
      duplicate:"Duplicate",
      delete:"Delete",
    }
  },
  add_user: {
    email: "Email Address",
    phone_number: "Phone Number",
    gender: "Gender",
    class: "Class",
    full_name: "Full Name",
    submit_button: "Submit",
  },
  edit_user: {
    email: "Email Address",
    phone_number: "Phone Number",
    gender: "Gender",
    class: "Class",
    status: "Status",
    password: "Password",
    full_name: "Full Name",
    submit_button: "Submit",
  },
  upload_csv: {
    title: ["Upload a file", "or drag and drop"],
    description:
      "Add a group of {{user_role}} and invite them to join your training courses and training products.",
    hint: {
      title:
        "Before uploading the file make sure the file follows our standards",
      directions: [
        "the file it should contain: full name , email,phone number and gender",
        "the header of these data it should be look like this:full_name,email,phone_number,gender ",
      ],
    },
    form: {
      description: "file should be less than or equal to 10 MB and types CSV ",
      submit: "Submit",
    },
  },

  users_table: {
    header: {
      full_name: "Full Name",
      gender: "Gender",
      phone_number: "Phone Number",
      email: "Email Address",
      points:"Points",
    },
    actions:{
      enroll_student:"Enroll Student"
    }
  },
  exams_and_assignment_table:{
    header:{
      id:"ID",
      name: "Name",
      mark: "Mark",
      attempts_number: "Number of attempts",
      exam_start_date:"Exam Start Date",
      assignment_start_date:"Assignment Start Date",
      deadline: "Deadline",
    }
  },
  lessons_table:{
    header:{
      id:"ID",
      title: "Title",
      details: "Details",
      file:"File",
    }
  },
  pagination:{
    showing:"Showing",
    to:"to",
    of:"Of",
    results:"results",
  }
};
