
import { lazy } from "react";
import {  RouteObject } from "react-router-dom";
import ProtectedRoute from "routes/middleware/ProtectedRoute";

const Subjects = lazy(() => import("pages/subjects/SubjectsPage")); 
const AddSubjectPage = lazy(() => import("pages/subjects/AddSubjectPage"));
const EditSubjectPage = lazy(() => import("pages/subjects/EditSubjectPage"));
const EnrollStudentsPage = lazy(() => import("pages/subjects/EnrollStudents")); 
const SubjectStudentsPage = lazy(() => import("pages/subjects/SubjectStudentsPage")); 

const SubjectsRoutes = (): RouteObject[] => {
    const routes: RouteObject[] = [
      {
        path: "",
        element: <ProtectedRoute> <Subjects/> </ProtectedRoute> ,
      },
      {
        path: "store",
        element: (
          <ProtectedRoute>
            <AddSubjectPage />
            </ProtectedRoute>
        ),
      },
      {
        path: ":id",
        element: (
          <ProtectedRoute>
            <EditSubjectPage />
          </ProtectedRoute>
        ),
      },
      {
        path: ":id/enroll-student",
        element: (
          <ProtectedRoute>
            <EnrollStudentsPage />
          </ProtectedRoute>
        ),
      },

      {
        path: ":id/students",
        element: (
          <ProtectedRoute>
            <SubjectStudentsPage />
          </ProtectedRoute>
        ),
      },

      

    ];
  
    return routes;
  };
  export default SubjectsRoutes;