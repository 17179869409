

import { createSlice } from "@reduxjs/toolkit";

import { examInitialData, examInitialDataArray } from "types/Exam";
import { PaginationInitialData } from "types/Pagination";


const initialState = {
    exam: {
    is_initial_data:true,
    exams:examInitialDataArray,
    selectedExam:examInitialData,
    tablePagination:PaginationInitialData
  },
};

export const ExamSlice  = createSlice({
  name: "ExamData",
  initialState,
  reducers: {
    storeExams: (state, action) => {
      state.exam.is_initial_data = false;
      state.exam.exams = action.payload;
    },
    storeSelectedExam: (state, action) => {
      state.exam.is_initial_data = false;
      state.exam.selectedExam = action.payload;
    },
    setTablePagination: (state, action) => {
      state.exam.is_initial_data = false;
      state.exam.tablePagination = action.payload;
    },

  },
});

export const { storeExams,storeSelectedExam,setTablePagination } =
ExamSlice.actions;
export default ExamSlice.reducer;