
import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import { PublicRoutes } from "../middleware/PublicRoutes";


const Login = lazy(() => import("pages/auth/LoginPage"));
const VerifyOTPs = lazy(() => import("pages/auth/VerifyOTPsPages"));
const SetPassword = lazy(() => import("pages/auth/SetPasswordPage"));


const AuthRoutes = (): RouteObject[] => {
    const routes: RouteObject[] = [
      {
        path: "",
        element: <Navigate to="login" />,
      },
      {
        path: "login",
        element: (
          <PublicRoutes>
            <Login />
         </PublicRoutes>
        ),
      },
      {
        path: "verify",
        element:   <PublicRoutes> <VerifyOTPs />  </PublicRoutes> ,
      },
      {
        path: "set-password",
        element:   <PublicRoutes> <SetPassword />  </PublicRoutes> ,
      },
   
    ];
  
    return routes;
  };
  export default AuthRoutes;