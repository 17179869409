
import subject from "api/ClientPortal/subject";
import { AxiosResponse, isAxiosError } from "axios";
import Preloader from "components/global/Preloader";
import { ErrorCodesEnum } from "enums/ErrorCodesEnum";
import { useParams,Navigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { setSelectedSubject } from "store/slice/SubjectSlice";
import { CheckAllowedToAccess } from "services/CheckAllowedToAccess";

const SubjectRoute = ({ children }: any) => {
  const dispatch = useDispatch();
  const params = useParams() as { id: string};

  const { isLoading, error, data } = useQuery("subject", () => {
    return subject.getSubject(params.id);
  });

  if (!CheckAllowedToAccess("Subjects_Details")) {
    return <Navigate to="/dashboard" replace />;
  }


  if (isLoading) {
    return <Preloader />;
  }

  if (error) {
    if (isAxiosError(error)) {
      if (
        error.code === ErrorCodesEnum.ERR_NETWORK ||
        error.code === ErrorCodesEnum.ERR_CANCELED
      ) {
        return <Navigate to="/" replace />;
      } else {
       
        switch (error.response?.status) {
          case 401:
            return <Navigate to="/auth/login" />;
          case 404:
            return <Navigate to="/dashboard" replace />;
          case 500:
            return <Navigate to="/auth/login" />;
        }
      }
    }
  }
  let response = data as AxiosResponse;
  dispatch(setSelectedSubject(response.data.data));

  return children;
};

export default SubjectRoute;
