export type Subject = {
    id:string;
    name: string
     logo: string | null,
     color: string 
}


export const subjectInitialData :Subject = {
    id:"",
    name:"",
    logo:null,
    color:""
}

export const subjectInitialDataArray :Subject[] = [];