import profile from "api/ClientPortal/profile";
import { AxiosResponse, isAxiosError } from "axios";
import Preloader from "components/global/Preloader";
import { ErrorCodesEnum } from "enums/ErrorCodesEnum";
import { RefCodeTypesEnum } from "enums/RefCodeTypesEnum";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { clearAllLocalStorage, getSchoolId, getToken, storageUserData } from "services/localStorage-service";

export const PublicRoutes = ({ children }: any, name: string) => {
  const dispatch = useDispatch();
  const { isLoading, error, data } = useQuery("user", () => {
    return profile.loadUserBasicInfo();
  });


 
 
  if (getToken() != null) {
    if (isLoading) {
      return <Preloader />;
    }

    if (error) {
    
      if (isAxiosError(error)) {
        if (
          error.code === ErrorCodesEnum.ERR_NETWORK ||
          error.code === ErrorCodesEnum.ERR_CANCELED
        ) {
          return <Navigate to="/" replace />;
        } else {
          if (error.response?.status == 400) {
             
            switch (error.response?.data.code) {
              case RefCodeTypesEnum.Need_To_Provide_Tenant_ID:
                 <Navigate to="/"  />;
                 break;
              case RefCodeTypesEnum.Tenant_Not_Found:
                <Navigate to="/" replace />;
                break;
            }
          }
          switch (error.response?.status) {
            case 401:
              clearAllLocalStorage();
               <Navigate to="/auth/login" replace />;
               break;
            case 500:
              clearAllLocalStorage();
              return <Navigate to="/auth/login"  />;
          }
        }
      } else {
        return children;
      }
    }else{
      let response = data as AxiosResponse;
      storageUserData(response.data.data);
      switch (response.data.code) {
        case 200:
          return <Navigate to="/dashboard" replace />;
      }
    }

   
  }

  return children;
};
