import { getLang } from "../../services/localStorage-service";
import { createSlice } from "@reduxjs/toolkit";
import { userInitialDataArray,userInitialData, userBasicInfoInitialDataArray } from "types/User";
import { PaginationInitialData } from "types/Pagination";

const initialState = {
  teacher: {
    is_initial_data:true,
    teachers:userInitialDataArray,
    selectedTeacher:userInitialData,
    teachersBasicInfo:{
      isInitialData:true,
      teachers:userBasicInfoInitialDataArray
    },
    tablePagination:PaginationInitialData
  },
};

export const TeacherSlice  = createSlice({
  name: "TeacherData",
  initialState,
  reducers: {
    setTeachers: (state, action) => {
      state.teacher.is_initial_data = false;
      state.teacher.teachers = action.payload;
    },
    setSelectedTeacher: (state, action) => {
      state.teacher.is_initial_data = false;
      state.teacher.selectedTeacher = action.payload;
    },
    setTeachersBasicInfo: (state, action) => {
      state.teacher.teachersBasicInfo.isInitialData = false;
      state.teacher.teachersBasicInfo.teachers = action.payload;
    },
    setTablePagination: (state, action) => {
      state.teacher.is_initial_data = false;
      state.teacher.tablePagination = action.payload;
    },

  },
});

export const { setTeachers,setSelectedTeacher ,setTeachersBasicInfo,setTablePagination} =
TeacherSlice.actions;
export default TeacherSlice.reducer;