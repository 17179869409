import { ExamContainer } from "./ExamContainer";

export type Exam = {
  id: string;
  name: string;
  mark: number;
  deadline: string;
  start_at:string;
  max_points:number;
  winning_points:number;
  content: ExamContainer[];
};



export const examInitialData:Exam = {
  id: "",
  name: "",
  mark: 0,
  deadline: "",
  start_at:"",
  max_points:0,
  winning_points:0,
  content: [],

}


export const examInitialDataArray:Exam[] = [];