import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import ProtectedRoute from "routes/middleware/ProtectedRoute";
import SubjectRoute from "routes/middleware/SubjectRoute";

const ExamRoutes = (): RouteObject[] => {
  const StoreExam = lazy(() => import("pages/Exam/StoreExam"));
  const ExamsPage = lazy(() => import("pages/Exam/ExamsPage"));
  const EditExam = lazy(() => import("pages/Exam/EditExamPage"));
  const GradeAdministration =lazy(() => import("pages/Exam/GradeAdministrationPage"));
  const routes: RouteObject[] = [
    {
      path: "",
      element: (
        <ProtectedRoute>
          <SubjectRoute>
            <ExamsPage />
          </SubjectRoute>
        </ProtectedRoute>
      ),
    },
    {
      path: "store",
      element: (
        <ProtectedRoute>
          <SubjectRoute>
            <StoreExam />
          </SubjectRoute>
        </ProtectedRoute>
      ),
    },

    {
      path: ":exam_id",
      element: (
        <ProtectedRoute>
          <SubjectRoute>
            <EditExam />
          </SubjectRoute>
        </ProtectedRoute>
      ),
    },

    {
      path: ":exam_id/grade-administration",
      element: (
        <ProtectedRoute>
          <SubjectRoute>
            <GradeAdministration />
          </SubjectRoute>
        </ProtectedRoute>
      ),
    },

    
  ];

  return routes;
};
export default ExamRoutes;
