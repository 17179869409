import { lazy } from "react";
import {  RouteObject } from "react-router-dom";
import ProtectedRoute from "routes/middleware/ProtectedRoute";
import SubjectRoute from "routes/middleware/SubjectRoute";

const ExamRoutes = (): RouteObject[] => {
  const StoreLesson = lazy(() => import("pages/Lesson/StoreLesson"));
  const EditLesson = lazy(() => import("pages/Lesson/EditLesson"));
  const LessonsPage = lazy(() => import("pages/Lesson/LessonsPage"));
  const routes: RouteObject[] = [

    {
      path: "",
      element: (
        <ProtectedRoute>
           <SubjectRoute>
           <LessonsPage />
           </SubjectRoute>
        </ProtectedRoute>
      ),
    },
    {
      path: "store",
      element: (
        <ProtectedRoute>
             <SubjectRoute>
             <StoreLesson />
             </SubjectRoute>
       
        </ProtectedRoute>
      ),
    },

    {
      path: ":lesson_id",
      element: (
        <ProtectedRoute>
           <SubjectRoute>
           <EditLesson />
           </SubjectRoute>
         
        </ProtectedRoute>
      ),
    },
  ];

  return routes;
};
export default ExamRoutes;
