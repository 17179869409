export const components: any = {
  side_bar: {
    dashboard: "لوحة التحكم",
    teacher: "المعلمين",
    students: "الطلاب",
    classes: "الفصول",
    admins:"مدراء النظام",
    billing: "الفواتير",
    settings_and_profile: "الاعدادت / الحساب",
    exam: "الاختبارات",
    message:"الرسائل",
    subject:"دروس"
  },

  

  subject_card: {
    exams: " الاختبارات",
    lessons: "الدروس",
    assignments: "الواجبات",
    students_administration: "اداره الطلاب",
    grade_administration:"اداره الدرجات",
    edit_subject:"تعديل",
    delete_subject:"حذف",
    enroll_student:"تسجيل الطالب",
    students:"الطلاب"
      
    
  },
  element_playground:{
    question_type:"نوع السؤال",
    grade:"الدرجة",
    correct_answer:"الاجابة الصحيحة",
    question_placeholder:"أكتب سؤالك هنا...",
    actions:{
      add_question:"اضف سؤال",
      duplicate:"تكرار",
      delete:"حذف"
    }
  },
  add_user: {
    email: "البريد الالكتروني",
    phone_number: "رقم الهاتف",
    gender: "الجنس",
    class: "الفصل",
    full_name: "الاسم الكامل",
    submit_button: "إرسال",
  },
  edit_user: {
    email: "البريد الالكتروني",
    phone_number: "رقم الهاتف",
    gender: "الجنس",
    class: "الفصل",
    full_name: "الاسم الكامل",
    status:"الحالة",
    password:"كلمة المرور",
    submit_button: "إرسال",
  },
  upload_csv: {
    title: ["قم بتحميل ملف", "أو قم بالسحب والإفلات"],
    description: "قم بإضافة مجموعه من {{user_role}} و دعوتهم لانضمام للدورات التدريبيه و منتجات التدريب الخاصه لك.",
    hint:{
        title:" قبل تحميل الملف، تأكد من أن الملف يتبع معاييرنا",
        directions:[
            "الملف الذي يجب أن يحتوي على: الاسم الكامل،رقم التليفون،بريد إلكتروني،جنس",
            "يجب أن يكون رأس هذه البيانات كما يلي: full_name,email,phone_number,gender "
         ]
    },

    form: {
      description:
        "يجب أن يكون حجم الملف أقل من أو يساوي 30 ميجابايت وأن يكون بصيغة CSV",
        submit:"اضف"
    },
  },
  users_table:{
    header:{
      full_name: "الاسم الكامل",
      gender: "الجنس",
      phone_number: "رقم الهاتف",
      email: "البريد الالكتروني",
      points:"النقاط",
    } ,actions:{
      enroll_student:"تسجيل الطالب"
    }
  },

  exams_and_assignment_table:{
    header:{
      id:"الرقم المعرفي",
      name: "الاسم",
      mark: "الدرجة",
      attempts_number: "عدد المحاولات",
      exam_start_date:"موعد بدء الاختبار",
      assignment_start_date:"موعد بدء الواجب",
      deadline: "موعد التسليم",
    }
  },
  lessons_table:{
    header:{
      id:"الرقم المعرفي",
      title: "العنوان",
      details: "الوصف",
      file:"الملف"
    }
  },
  pagination:{
    showing:"عرض",
    to:"الى",
    of:"من أصل",
    results:"نتيجة"
  }
};
