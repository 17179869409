import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import ProtectedRoute from "routes/middleware/ProtectedRoute";
import SubjectRoute from "routes/middleware/SubjectRoute";

const AssignmentRoutes = (): RouteObject[] => {
  const StoreAssignment = lazy(() => import("pages/Assignment/StoreAssignment"));
  const AssignmentsPage = lazy( () => import("pages/Assignment/AssignmentsPage"));
  const EditAssignment = lazy( () => import("pages/Assignment/EditAssignmentPage") );
  const GradeAdministration = lazy( () => import("pages/Assignment/GradeAdministrationPage") );
  const routes: RouteObject[] = [
    {
      path: "",
      element: (
        <ProtectedRoute>
          <SubjectRoute>
            <AssignmentsPage />
          </SubjectRoute>
        </ProtectedRoute>
      ),
    },
    {
      path: "store",
      element: (
        <ProtectedRoute>
          <SubjectRoute>
            <StoreAssignment />
          </SubjectRoute>
        </ProtectedRoute>
      ),
    },

    {
      path: ":assignment_id",
      element: (
        <ProtectedRoute>
          <SubjectRoute>
            <EditAssignment />
          </SubjectRoute>
        </ProtectedRoute>
      ),
    },
    {
      path: ":assignment_id/grade-administration",
      element: (
        <ProtectedRoute>
          <SubjectRoute>
            <GradeAdministration />
          </SubjectRoute>
        </ProtectedRoute>
      ),
    },

    
  ];

  return routes;
};
export default AssignmentRoutes;
