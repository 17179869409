export type Lesson = {
    id:string;
    title:string;
    details:string;
    file:string;
}


export const lessonInitialData : Lesson ={
    id:"",
    title:"",
    details:"",
    file:""
}

export const lessonInitialDataArray:Lesson[] = [];