import { createSlice } from "@reduxjs/toolkit";
import { AllowedUsersInitialDataArray, conversationInitialData, conversationInitialDataArray } from "types/Conversation";

const initialState = {
   conversation: {
    is_initial_data:true,
    conversations:conversationInitialDataArray,
    selectedConversation:conversationInitialData,
    allowedUsers:AllowedUsersInitialDataArray
  },
};

export const ConversationSlice  = createSlice({
  name: "ConversationData",
  initialState,
  reducers: {
    storeConversations: (state, action) => {
      state.conversation.is_initial_data = false;
      state.conversation.conversations = action.payload;
    },
    storeSelectedConversation: (state, action) => {
      state.conversation.is_initial_data = false;
      state.conversation.selectedConversation = action.payload;
    },
    updateConversations: (state, action) => {
      state.conversation.is_initial_data = false;
      state.conversation.conversations = action.payload;
    },
    storeAllowedUsers: (state, action) => {
      state.conversation.is_initial_data = false;
      state.conversation.allowedUsers = action.payload;
    },

  },
});

export const { storeConversations,storeSelectedConversation,storeAllowedUsers } =
ConversationSlice.actions;
export default ConversationSlice.reducer;