import { User } from "types/User";



export enum LocalStorageKeys {
  token = "USER_TOKEN",
  language = "lang",
  user_data = "USER_DATA",
  school_ID = "SCHOOL_ID",
}


export const storageUserData = (userData: object): void => {
  localStorage.setItem(LocalStorageKeys.user_data, JSON.stringify(userData));
};
export const getStoredUserData = (): User | null => {
  if (localStorage.getItem(LocalStorageKeys.user_data)) {
    return JSON.parse(localStorage.getItem(LocalStorageKeys.user_data) || "");
  }
  return null;
};

export const setLang = (lang: string): void => {
  localStorage.setItem(LocalStorageKeys.language, lang);
};
export const getLang = (): string => {
  return null ?? (localStorage.getItem(LocalStorageKeys.language) ?? "en");
};

export const setSchoolId = (id: string): void => {
  localStorage.setItem(LocalStorageKeys.school_ID, id);
};
export const getSchoolId = (): string | null => {
  return null ?? (localStorage.getItem(LocalStorageKeys.school_ID) ?? null);
};


export const setToken = (token: string | null): void => {
  token && localStorage.setItem(LocalStorageKeys.token, token);
};
export const getToken = (): string | null => {
  const storedToken = localStorage.getItem(LocalStorageKeys.token);
  if (storedToken) {
    return storedToken;
  }
  return null;
};

export const getProfileAvatar = (): string | null => {
  let userData = JSON.parse(localStorage.getItem(LocalStorageKeys.user_data) as string);
  return userData.avatar;
};

export const removeToken = (): void => {
  localStorage.removeItem(LocalStorageKeys.token);
};



export const setLocalStorageWithExpiry = (
  key: LocalStorageKeys,
  value: unknown,
  expierDuration = 10 * 60 * 60
) => {
  const now = new Date();
  const item = {
    value,
    expireOn: now.getTime() + expierDuration,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorageWithExpiry = (key: LocalStorageKeys) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expireOn) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const setLocalStorage = (key: LocalStorageKeys, value: unknown) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key: string) => {
  try {
    const itemStr = localStorage.getItem(key);
    return itemStr ? JSON.parse(itemStr) : null;
  } catch (e) {
    return null;
  }
};

export const clearLocalStorageByKey = (key: LocalStorageKeys) => {
  localStorage.removeItem(key);
};

export const clearAllLocalStorage = () => {
  clearLocalStorageByKey(LocalStorageKeys.token);
  clearLocalStorageByKey(LocalStorageKeys.user_data);
};
