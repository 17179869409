

import { ClientPortalHttpService } from "api/HttpService";
import { AxiosResponse } from "axios";
import { getStoredUserData } from "services/localStorage-service";

export default {
  store(data: object): Promise<AxiosResponse<any, any>> {
    return ClientPortalHttpService().post("/admin/subjects", data);
  },
  delete(id:string): Promise<AxiosResponse<any, any>> {
    return ClientPortalHttpService().delete(`/admin/subjects/${id}`);
  },
  edit(data: object,id:string): Promise<AxiosResponse<any, any>> {
    return ClientPortalHttpService().put(`admin/subjects/${id}`, data);
  },

  getAllSubjects(): Promise<AxiosResponse<any, any>> {
    return ClientPortalHttpService().get(`${getStoredUserData()?.role.toLocaleLowerCase()}/subjects`);
  },

  getSubject(id: string): Promise<AxiosResponse<any, any>> {
    return ClientPortalHttpService().get(`${getStoredUserData()?.role.toLocaleLowerCase()}/subjects/${id}`);
  },

  getUnenrolledStudent(id: string): Promise<AxiosResponse<any, any>> {
    return ClientPortalHttpService().get(`${getStoredUserData()?.role.toLocaleLowerCase()}/subjects/${id}/unenrolled-student`);
  },
  getUnenrolledStudentWithPagination(id: string,page:number): Promise<AxiosResponse<any, any>> {
    return ClientPortalHttpService().get(`${getStoredUserData()?.role.toLocaleLowerCase()}/subjects/${id}/unenrolled-student?page=${page}`);
  },
  enrollStudent(data:object): Promise<AxiosResponse<any, any>> {
    return ClientPortalHttpService().post(`${getStoredUserData()?.role.toLocaleLowerCase()}/subjects/enroll-student`,data);
  },
  getStudents(id:string): Promise<AxiosResponse<any, any>> {
    return ClientPortalHttpService().get(`${getStoredUserData()?.role.toLocaleLowerCase()}/subjects/${id}/students`);
  },
  getStudentsWithPagination(id:string,pageNumber:number): Promise<AxiosResponse<any, any>> {
    return ClientPortalHttpService().get(`${getStoredUserData()?.role.toLocaleLowerCase()}/subjects/${id}/students?page=${pageNumber}`);
  },

  searchStudentsWithPagination(id:string,pageNumber:number,searchTerm:string): Promise<AxiosResponse<any, any>> {
    return ClientPortalHttpService().get(`${getStoredUserData()?.role.toLocaleLowerCase()}/subjects/${id}/students/search?search_term=${searchTerm}&page=${pageNumber}`);
  },



  


  
 

 

  
  
  
 
};