import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import ProtectedRoute from "routes/middleware/ProtectedRoute";

// const Login = lazy(() => import("./pages/error/NotFoundPage"));
const Students = lazy(() => import("pages/student/StudentsPage"));
const AddStudentPage = lazy(() => import("pages/student/AddStudentPage"));
const EditStudentPage = lazy(() => import("pages/student/EditStudentPage"));
const StudentRoutes = (): RouteObject[] => {
  const routes: RouteObject[] = [
    {
      path: "",
      element: (
        <ProtectedRoute>
          <Students />
        </ProtectedRoute>
      ),
    },
    {
      path: "store",
      element: (
        <ProtectedRoute>
          <AddStudentPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ":id",
      element: (
        <ProtectedRoute>
          <EditStudentPage />
        </ProtectedRoute>
      ),
    },
  ];

  return routes;
};
export default StudentRoutes;
