export const lists: any = {

    gender:{
        male:"ذكر",
        female:"انثى"
    },
    status:{
        active:"نشط",
        inactive:"غير نشط",
        pending:"قيد الانتظار"
    },
    time:{
        am:"ًصباحًا",
        pm:"مساءًا"
      },

      element_list:{
        "short_answer":"إجابة قصيرة",
        "long_answer":"إجابة طويلة",
        "multiple_choice":"إختيار من متعدد",
        "content":"محتوى"
      },

}