import { Grid, LinearProgress } from "@mui/material";
import LoaderImg from "assets/images/CubePlatformLogo.svg"

const Preloader = () => {
  return (
    <Grid
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
    sx={{ height: "100vh" }}
  >
    <img className="mobile:w-[120px] tablet:w-[150px] w-[200px]" src={LoaderImg} alt="CubePlatformLogo" loading="lazy" />
    <div className=" my-10  mobile:my-5 flex justify-center items-center">
      <LinearProgress
        color="inherit"
        className=" w-[100px] h-[5px] mobile:h-[4px]"
        sx={{
          borderRadius: "8px",
          color: "#5275CF",
        }}
      />
    </div>
  </Grid>
  );
};

export default Preloader;