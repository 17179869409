import { AssignmentContainer } from "./AssignmentContainer";

export type Assignment = {
  id: string;
  name: string;
  attempts_number: number;
  deadline: string;
  start_at: string;
  max_points:number;
  winning_points:number;
  content: AssignmentContainer[];
};

export const assignmentInitialData:Assignment = {
  id: "",
  name: "",
  attempts_number: 0,
  deadline: "",
  start_at:"",
  max_points:0,
  winning_points:0,
  content: [],
}


export const assignmentInitialDataArray:Assignment[] = [];

