
import LoginPage from "../../pages/auth/LoginPage";
import VerifyOTPsPages from "../../pages/auth/VerifyOTPsPages";
import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import { PublicRoutes } from "../middleware/PublicRoutes";
import ProtectedRoute from "routes/middleware/ProtectedRoute";

// const Login = lazy(() => import("./pages/error/NotFoundPage"));
const Teachers = lazy(() => import("pages/teacher/TeachersPage")); 
const AddTeacherPage = lazy(() => import("pages/teacher/AddTeacherPage"));
const EditTeacherPage = lazy(() => import("pages/teacher/EditTeacherPage"));
const TeachersRoutes = (): RouteObject[] => {
    const routes: RouteObject[] = [
      {
        path: "",
        element: <ProtectedRoute> <Teachers/> </ProtectedRoute> ,
      },
      {
        path: "store",
        element: (
          <ProtectedRoute>
            <AddTeacherPage />
            </ProtectedRoute>
        ),
      },
      {
        path: ":id",
        element: (
          <ProtectedRoute>
            <EditTeacherPage />
          </ProtectedRoute>
        ),
      },

    ];
  
    return routes;
  };
  export default TeachersRoutes;