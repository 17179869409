import { getLang, setSchoolId } from "services/localStorage-service";
import cubePlatformLogo from "assets/images/CubePlatformLogo.svg";
import {useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdLanguage } from "react-icons/md";
import { getLangDir } from "helpers/get-lang-dir";
import { useDispatch } from "react-redux";
import { setLocalLang, setTenant } from "../store/slice/AppSettingsSlice";
import { ChangeLanguage } from "services/lang-service";
import { useState } from "react";
import CustomInput from "components/global/inputs/CustomInput";
import CustomButton from "components/global/buttons/CustomButton";
import ClientPortal from "api/ClientPortal";
const OrganizationLoginPage = () => {
  //hooks
  const { t } = useTranslation();
  const staticData: any = t("pages.organization_login_page");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //states

  const [schoolName, setSchoolName] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [isButtonLoading,setIsButtonLoading] = useState<boolean>(false);    
  //handler
  const handelOnClickLangBtn = () => {
    let currentLang = getLang();
    ChangeLanguage(currentLang == "ar" ? "en" : "ar");
    dispatch(setLocalLang(currentLang == "ar" ? "en" : "ar"));
  };

  const handelOnChangeSchoolName = (e: any) => {
    if(e.target.value == ""){
      setIsButtonDisabled(true);
    }else{
      setIsButtonDisabled(false);
    }
    setSchoolName(e.target.value);
  
  };
  const handelOnClickBtn = () => {
    setErrorMessage("");
    setError(false);
    setIsButtonDisabled(true);
    setIsButtonLoading(true);
    let data={
      school_name: schoolName
    }
    
    ClientPortal.verifySchool(data).then((res)=>{
      setSchoolId(res.data.data.school_id);
      dispatch(setTenant(res.data.data.school_id));
      setIsButtonDisabled(false);
      setIsButtonLoading(false);
      navigate("auth/login")
    })
    .catch((err)=>{
      setError(true);
     setErrorMessage(err.response.data.message);
     setIsButtonDisabled(false);
      setIsButtonLoading(false);
    });
  };
  return (
    <div
      className={`grid grid-cols-12 h-screen w-screen`}
      style={{
        fontFamily: `${getLang() == "ar" ? "Cairo" : "Kumbh Sans"}`,
        direction: getLangDir(),
      }}
    >
      <div
        className={` login-organization-page-${getLang()} col-span-6  w-full h-full flex justify-center items-center mobile:hidden`}
      >
        <div className="text-center">
          <div className="flex justify-center items-center my-5">
            <img
              src={cubePlatformLogo}
              alt="cube platform logo"
              width="180px"
            />
          </div>
          <h1
            className="text-[#6E7885] text-2xl font-normal"
            style={{ fontFamily: `${getLang() == "ar" ? "Cairo" : "Poppins"}` }}
          >
            {staticData.description}
          </h1>
        </div>
     
      </div>
      <div className="col-span-6  w-full h-full flex justify-center items-center mobile:col-span-12">
        <div className="   w-full h-full grid grid-rows-12 ">
          <div className=" row-span-1  flex justify-end items-start p-2">
            <button
              className=" flex justify-center items-center bg-inherit border-none p-2"
              onClick={handelOnClickLangBtn}
            >
              <p
                className="px-3"
                style={{
                  fontFamily: `${getLang() == "en" ? "Cairo" : "Poppins"}`,
                }}
              >
                {getLang() == "en" ? "العربية" : "English"}
              </p>
              <MdLanguage size={"24px"} />
            </button>
          </div>
          <div className=" row-span-11 flex justify-center items-center">
            <div className="text-center">
              <div className="flex justify-center items-center my-5 tablet:hidden xl:hidden">
                <img
                  src={cubePlatformLogo}
                  alt="cube platform logo"
                  width="180px"
                />
              </div>

              <h1 className="text-[#4F4F4F] font-semibold text-2xl ">
                {staticData.form.title}
              </h1>
              <p className="text-[#667085] text-base font-medium my-5">
               {staticData.form.label}
              </p>
              <CustomInput
                error={error}
                errorMessage={errorMessage}
                type="text"
                placeholder={staticData.form.placeholder}
                value={schoolName}
                onChangeValue={handelOnChangeSchoolName}
              />
              <CustomButton
                onClick={handelOnClickBtn}
                disabled={isButtonDisabled}
                isLoading={isButtonLoading}
                text={staticData.form.submit_button}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationLoginPage;
