export const pages: any = {
  organization_login_page: {
    description: "لخلق بيئة تعليمية جذابة",
    form: {
      title: "مرحباً، قم بتسجيل الدخول إلى مدرستك",
      label: "أدخل اسم المدرسة",
      placeholder: "اسم المدرسة",
      submit_button: "إرسال",
    },
  },
  login_page: {
    title: "مرحباً، قم بتسجيل الدخول إلى حسابك",
    description: "إنه لمن دواعي سرورنا أن تكون معنا!",
    form: {
      email: {
        placeholder: "ادخل البريد الالكتروني",
      },
      password: {
        placeholder: "ادخل كلمة المرور",
      },
      submit_button: "إرسال",
    },
  },
  verify_otp: {
    title: "تحقق من حسابك",
    description: [
      "أدخل رمز التحقق المرسل إلى بريدك الإلكتروني.",
      "احرص على عدم مشاركة الرمز مع أي شخص",
    ],
    form: {
      timer: "ثانيه",
      submit_button: "إرسال",
      resend_otp: {
        label: "لم تستلم الرمز؟",
        button_text: "ارسل الرمز مجدادً",
      },
    },
  },

  set_password_page: {
    title: "ضبط كلمة المرور",
    description: [
      "لحماية حسابك، تأكد من كلمة المرور الخاصة بك:",
      "أطول من 7 أحرف.",
      "يتضمن حرفًا كبيرًا واحدًا على الأقل.",
      "يتضمن حرفًا صغيرًا واحدًا على الأقل.",
      "يتضمن رقمًا واحدًا على الأقل",
      "يتضمن حرفًا خاصًا واحدًا على الأقل",
    ],
    form: {
      current_password: {
        placeholder: "كلمة المرور الحالية",
      },
      password: {
        placeholder: " كلمة المرور الجديده",
      },
      re_password: {
        placeholder: " إعادة كلمة المرور",
      },
      submit_button: "إرسال",
    },
  },
  dashboard: {
    admin: {
      title: "مرحبا بكم في لوحة المعلومات الخاصة بك",

      dashboard_functionality_list: {
        descriptions: {
          add_teacher_description:
            "أضف معلمين لمساعدتك في تحقيق أهداف مؤسستك.!",
          add_classes_description:
            "أنشئ محتوى دورة تدريبية غنيًا ومنتجات تدريبية لطلابك. عندما تقدم لهم خطة تسعير، سوف تظهر على موقعك!",
          add_student_description:
            "قم بإضافة الطلاب لجعلهم يتفاعلون مع محتوى الدورات التدريبية ومنتجات التدريب الخاصة بك.",
        },
        title: {
          add_teacher: "أضف معلمين",
          add_classes: "أضف مواد",
          add_student: "أضف طلاب",
        },
      },
    },
    super_admin: {
      title: "مرحبا بكم في لوحة المعلومات الخاصة بك",

      dashboard_functionality_list: {
        descriptions: {
          add_teacher_description:
            "أضف معلمين لمساعدتك في تحقيق أهداف مؤسستك.!",
          add_classes_description:
            "أنشئ محتوى دورة تدريبية غنيًا ومنتجات تدريبية لطلابك. عندما تقدم لهم خطة تسعير، سوف تظهر على موقعك!",
          add_student_description:
            "قم بإضافة الطلاب لجعلهم يتفاعلون مع محتوى الدورات التدريبية ومنتجات التدريب الخاصة بك.",
        },
        title: {
          add_teacher: "أضف معلمين",
          add_classes: "أضف مواد",
          add_student: "أضف طلاب",
        },
      },
    },
    teacher: {
      title: "مرحبا بكم في لوحة المعلومات الخاصة بك",

      messages: {
        empty_list: {
          title: "لم يتم تعيين أي دروس لك.",
          description: "ستظهر الدروس هنا بعد تعيينها لك .",
        },
      },
    },
    student: {
      title: "مرحبا بكم في لوحة المعلومات الخاصة بك",

      messages: {
        empty_list: {
          title: "لم يتم تسجيلك في أي دروس .",
          description: "ستظهر الدروس المسجله هنا.",
        },
      },
    },
  },
  teachers: {
    title: "المعلمين",
    actions: {
      add_teacher: "اضف معلم",
      export_csv: "تصدير CSV",
    },
    messages: {
      empty_list: {
        title: "لا يوجد معلمين",
        description: "سيظهر المعلمون هنا بعد تسجيلهم في مدرستك.",
      },
    },
  },

  exams: {
    title: "الاختبارات",
    actions: {
      add_exam: "إنشاء اختبار",
    },
    messages: {
      empty_list: {
        title: "  لا يوجد إختبارات ",
        description: "ستظهر جميع الاختبارات هنا بعد إنشائها",
      },
    },
  },
  lessons: {
    title: "الدروس",
    actions: {
      add_lesson: "إنشاء درس",
    },
    messages: {
      empty_list: {
        title: "  لا يوجد دروس ",
        description: "ستظهر جميع الدروس هنا بعد إنشائها",
      },
    },
  },

  assignments: {
    title: "الواجبات",
    actions: {
      add_assignment: "انشاء واجب",
    },
    messages: {
      empty_list: {
        title: "  لا توجد واجبات ",
        description: "ستظهر جميع الواجبات هنا بعد إنشائها",
      },
    },
  },

  students: {
    title: "الطلاب",
    actions: {
      add_student: "اضف طالب",
      export_csv: "تصدير CSV",
    },
    messages: {
      empty_list: {
        title: "لا يوجد طلاب",
        description: "سيظهر الطلاب هنا بعد تسجيلهم في مدرستك.",
      },
    },
  },

  subjects: {
    title: "الدروس",
    actions: {
      add_subject: "اضف درس",
    },
    messages: {
      empty_list: {
        title: "لا يوجد دروس",
        description: "ستظهر الدروس هنا بعد تسجيلهم .",
      },
    },
  },

  admins: {
    title: "مدراء النظام",
    actions: {
      add_teacher: "اضف مدير نظام",
      export_csv: "تصدير CSV",
    },
    messages: {
      empty_list: {
        title: "لا يوجد مدراء نظام",
        description: "سيظهر مدراء النظام هنا بعد تسجيلهم في مدرستك.",
      },
    },
  },

  messages: {
    title: "الرسائل",
    form: {
      title: "ابدأ محادثة جديدة",
      user: {
        label: "اسم المستخدم",
        submit: " إبدأ المحادثه",
        cancel: "إلغاء",
      },
    },
    messages: {
      empty_list: {
        title: "اختار دردشة أو ابدأ محادثة جديدة",
      },
      empty_conversation: {
        title: "ليس لديك أي محادثات.",
      },
      actions: {
        add_conversation: "ابدأ محادثة جديدة",
      },
    },
  },

  add_student: {
    title: "أضف طالب",
    group_name: "الطلاب",
    actions: {
      manually: "يدوي",
      import_csv: "استيراد ملف CSV",
      add_student: "أضف طالب",
    },
  },

  add_subject: {
    title: "أضف درس",
    form: {
      subject_name: {
        label: "اسم الدرس",
      },
      teacher: {
        label: "اسم المعلم",
      },
      color: {
        label: "اللون",
      },
      image: {
        label: "الصورة",
      },
      points: {
        label: "عدد النقاط",
      },
    },
    actions: {
      add_subject: "أضف درس",
    },
  },

  edit_subject: {
    title: "تعديل درس",
    form: {
      subject_name: {
        label: "اسم الدرس",
      },
      color: {
        label: "اللون",
      },
      image: {
        label: "الصورة",
      },
    },
    actions: {
      edit_subject: "تعديل الدرس",
    },
  },
  enroll_student: {
    title: "تسجيل الطلاب",

    actions: {
      enroll_student: "تسجيل الطلاب",
    },
    messages: {
      empty_list: {
        title: "لا يوجد طلاب",
        description: "سيظهر جميع الطلاب الغير مسجلين في هذا الدرس هنا .",
      },
    },
  },
  subject_students: {
    title: " الطلاب",

    actions: {
      enroll_student: "تسجيل الطلاب",
      search:"ابحث عن الطلاب بالاسم أو البريد الإلكتروني"
    },
    messages: {
      empty_list: {
        title: "لا يوجد طلاب",
        description: "سيظهر جميع الطلاب الغير مسجلين في هذا الدرس هنا .",
      },
    },
  },

  add_teacher: {
    title: "أضف معلم",
    group_name: "معلمين",
    actions: {
      manually: "يدوي",
      import_csv: "استيراد ملف CSV",
      add_teacher: "أضف معلم",
    },
  },
  add_admin: {
    title: "أضف مدير نظام",
    group_name: "مدراء النظام",
    actions: {
      manually: "يدوي",
      import_csv: "استيراد ملف CSV",
      add_admin: "أضف مدير نظام",
    },
  },
  edit_student: {
    title: " تعديل بيانات طالب",
    group_name: "الطلاب",
    actions: {
      edit_student: "تعديل",
    },
  },
  edit_teachert: {
    title: " تعديل بيانات معلم",
    group_name: "معلمين",
    actions: {
      edit_teacher: "تعديل",
    },
  },
  edit_admin: {
    title: " تعديل بيانات مدير النظام",
    group_name: "مدراء النظام",
    actions: {
      edit_teacher: "تعديل",
    },
  },

  add_assignment: {
    title: "إضافة الواجب إلى ",
    form: {
      assignment_start_date: {
        label: " موعد بدء الواجب",
      },
      assignment_deadline: {
        label: " الموعد النهائي للواجب",
      },
      assignment_name: {
        label: "اسم الواجب",
      },
      attempts_number: {
        label: " عدد المحاولات",
      },
      max_points: {
        label: "مجموع النقاط",
      },
      winning_points: {
        label: "درجه الفوز",
      },
      actions: {
        add: "إضافه",
      },
    },
  },
  edit_assignment: {
    title: "تعديل الواجب إلى ",
    form: {
      assignment_start_date: {
        label: " موعد بدء الواجب",
      },
      assignment_deadline: {
        label: " الموعد النهائي للواجب",
      },
      assignment_name: {
        label: "اسم الواجب",
      },
      attempts_number: {
        label: " عدد المحاولات",
      },
      max_points: {
        label: "مجموع النقاط",
      },
      winning_points: {
        label: "درجه الفوز",
      },
      actions: {
        edit: "تعديل",
      },
    },
  },

  add_exam: {
    title: "إضافة الاختبار إلى ",
    form: {
      exam_deadline: {
        label: " الموعد النهائي للاختبار",
      },
      exam_start_date: {
        label: " موعد بدء للاختبار",
      },
      exam_name: {
        label: "اسم الاختبار",
      },
      exam_mark: {
        label: "درجه الاختبار",
      },
      max_points: {
        label: "مجموع النقاط",
      },
      winning_points: {
        label: "درجه الفوز",
      },
      actions: {
        add: "إضافه",
      },
    },
  },

  edit_exam: {
    title: "تعديل الاختبار إلى ",
    form: {
      exam_deadline: {
        label: " الموعد النهائي للاختبار",
      },
      exam_start_date: {
        label: " موعد بدء للاختبار",
      },
      exam_name: {
        label: "اسم الاختبار",
      },
      exam_mark: {
        label: "درجه الاختبار",
      },
      max_points: {
        label: "مجموع النقاط",
      },
      winning_points: {
        label: "درجه الفوز",
      },
      actions: {
        edit: "تعديل",
      },
    },
  },

  add_lesson: {
    title: "إضافة الدرس إلى ",
    form: {
      subject: {
        label: "العنوان",
      },
      details: {
        label: "تفاصيل",
      },
      actions: {
        submit: "إضافه",
        upload_file: "تحميل ملف",
        upload_video: "تحميل فيديو",
        upload_image: "تحميل صورة",
      },
    },
  },
  edit_lesson: {
    title: "تعديل الدرس  ",
    form: {
      subject: {
        label: "العنوان",
      },
      details: {
        label: "تفاصيل",
      },
      actions: {
        submit: "تعديل",
        upload_file: "تحميل ملف",
        upload_video: "تحميل فيديو",
        upload_image: "تحميل صورة",
      },
    },
  },
  gradeAdministration: {
    title: "ادارة الدرجات",
    actions: {
      back_to_exams: "الرجوع الى الاختبارات",
      back_to_assignment: "الرجوع الى الواجبات",
    },
    messages: {
      empty_list: {
        title: "لا يوجد تسليمات",
        description: "سوف تظهر جميع التسليمات هنا",
      },
    },
  },

  not_found_error_page:{
    title:"هذه الصفحه غير موجوده",
    error_code:"خطأ 404",
    description:"الصفحة التي تبحث عنها غير موجودة",
    actions:{
      back_button:"الرجوع للخلف",
      dashboard_button:"لوحة المعلومات",
    }
  },
  server_error_page:{
    
      title:"هناك خطأ ما",
      error_code:"خطأ 500",
      description:"عذرا، هنالك خطأ ما، الرجاء إعادة المحاولة لاحقا",
      actions:{
        reload_button:"إعادة تحميل الصفحة",
        dashboard_button:"لوحة المعلومات",
      }
    
  }
};
