import { buttons } from "./buttons";
import { components } from "./components";
import { lists } from "./lists";
import { messages } from "./messages";
import { pages } from "./pages";

export const ar: any = {
pages:pages,
buttons:buttons,
components:components,
lists:lists,
messages:messages
}