import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import ProtectedRoute from "routes/middleware/ProtectedRoute";

// const Login = lazy(() => import("./pages/error/NotFoundPage"));
const Admins = lazy(() => import("pages/admin/AdminsPage"));
const AddAdminPage = lazy(() => import("pages/admin/AddAdminPage"));
const EditAdminPage = lazy(() => import("pages/admin/EditAdminPage"));
const AdminRoutes = (): RouteObject[] => {
  const routes: RouteObject[] = [
    {
      path: "",
      element: (
        <ProtectedRoute>
          <Admins />
        </ProtectedRoute>
      ),
    },
    {
      path: "store",
      element: (
        <ProtectedRoute>
          <AddAdminPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ":id",
      element: (
        <ProtectedRoute>
          <EditAdminPage />
        </ProtectedRoute>
      ),
    },
  ];

  return routes;
};

export default AdminRoutes;
