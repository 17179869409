
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  token: "",
};

export const SetPasswordSlice = createSlice({
  name: "setPasswordData",
  initialState,
  reducers: {
    storeSetPasswordData: (state, action) => {
      state.token = action.payload;
    },
    clearSetPasswordData: (state) => {
      state.token = "";
    },
  },
});

export const { storeSetPasswordData, clearSetPasswordData } = SetPasswordSlice.actions;
export default SetPasswordSlice.reducer;