import { Navigate, createBrowserRouter, RouteObject } from "react-router-dom";
import NotFoundPage from "../pages/error/NotFoundPage";
import AuthRoutes from "./ClientPortal/AuthRoutes";
import Preloader from "../components/global/Preloader";
import ServerErrorPage from "../pages/error/ServerErrorPage";
import OrganizationLogin from "../pages/OrganizationLoginPage";
import Routes from "./ClientPortal/Routes";
import TeachersRoutes from "./ClientPortal/TeachersRoutes";
import StudentRoutes from "./ClientPortal/StudentRoutes";
import AdminRoutes from "./ClientPortal/AdminRoutes";
import MessageRoutes from "./ClientPortal/MessageRoutes";
import ExamRoutes from "./ClientPortal/ExamRoutes";
import LessonRoutes from "./ClientPortal/LessonRoutes";

import AssignmentRoutes from "./ClientPortal/AssignmentRoutes";
import { PublicRoutes } from "./middleware/PublicRoutes";
import SubjectsRoutes from "./ClientPortal/SubjectsRoutes";

const useRoutes = () => {
  const authRoutes = AuthRoutes();
  const GlobalRoutes = Routes();
  const TeachersRoute = TeachersRoutes();
  const StudentRoute = StudentRoutes();
  const adminRoutes = AdminRoutes();
  const messagesRoute = MessageRoutes();
  const examRoutes = ExamRoutes();
  const assignmentRoutes = AssignmentRoutes();
  const lessonRoutes = LessonRoutes();
  const subjectRoutes = SubjectsRoutes();

  const routes: RouteObject[] = [
    {
      path: "/",
      element: (
        <PublicRoutes>
          <OrganizationLogin />
        </PublicRoutes>
      ),
    },
    {
      path: "/auth",
      loader: Preloader,
      children: authRoutes,
    },
    {
      path: "/",
      loader: Preloader,
      children: GlobalRoutes,
    },
    {
      path: "/teachers",
      loader: Preloader,
      children: TeachersRoute,
      // errorElement:<ServerErrorPage/>
    },

    {
      path: "/students",
      loader: Preloader,
      children: StudentRoute,
      // errorElement:<ServerErrorPage/>
    },

    {
      path: "/admins",
      loader: Preloader,
      children: adminRoutes,
    },

    {
      path: "/messages",
      loader: Preloader,
      children: messagesRoute,
    },
    {
      path: "/subjects",
      loader: Preloader,
      children: subjectRoutes,
    },

    {
      path: "/subjects/:id/exams",
      loader: Preloader,
      children: examRoutes,
      // errorElement:<ServerErrorPage/>
    },
    {
      path: "/subjects/:id/assignments",
      loader: Preloader,
      children: assignmentRoutes,
    },
    {
      path: "/subjects/:id/lessons",
      loader: Preloader,
      children: lessonRoutes,
      // errorElement:<ServerErrorPage/>
    },

    {
      path: "*",
      element: <NotFoundPage />,
      // errorElement: <ServerErrorPage />,
    },
  ];

  return createBrowserRouter(
    routes.map((route) => ({
      ...route,
      errorElement: <ServerErrorPage/>
    }))
  );
};
export default useRoutes;
