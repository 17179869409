import { LegacyRef } from "react";
import Loading from "../Loding";

type CustomButtonProps = {
  text: string;
  onClick?: () => void;
  disabled: boolean;
  isLoading: boolean;
  type?: string;
  ref?:LegacyRef<HTMLButtonElement>
};

const CustomButton = ({
  disabled,
  text,
  isLoading,
  type="button",
  ref,
  onClick,
}: CustomButtonProps) => {
  return (
    <button
      className=" bg-primary border-none my-3 flex justify-center items-center rounded p-3 text-white font-bold text-sm w-full disabled:text-[#979797] disabled:bg-[#F8F9FB] disabled:cursor-not-allowed "
      disabled={disabled}
      onClick={onClick}
      ref={ref}
    >
      {isLoading ? <Loading /> : <>{text}</>}
    </button>
  );
};
export default CustomButton;
