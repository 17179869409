import { MdErrorOutline } from "react-icons/md";

type CustomInputProps = {
  value?: string | number;
  onChangeValue?: (e: any) => void;
  type: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  name?: string;
  readOnly?: boolean;
};

const CustomInput = ({
  value,
  type,
  placeholder,
  error,
  errorMessage,
  name,
  readOnly,
  onChangeValue,
}: CustomInputProps) => {
  return (
    <span>
      <input
        className={` remove-arrow border-[0.5px] h-10 w-full focus:border-[#8A8A8A]  outline-none p-2 ${error ? "border-[#EB3E3E] border" : "border-[#A7A7A7] border-[0.5px]"} rounded`}
        type={type}
        value={value}
        onChange={onChangeValue}
        placeholder={placeholder}
        name={name}
        readOnly={readOnly}
      />
      {(error && errorMessage != "")  && (
        <div className="flex justify-start py-2 items-center text-[#EB3E3E]">
            <MdErrorOutline/>

            <p className=" font-medium text-sm px-2">{errorMessage}</p>

        </div>
      )}
    </span>
  );
};

export default CustomInput;
