
import { createSlice } from "@reduxjs/toolkit";
import { containersInitialDataArray } from "types/Build/Container";


// import { containerInitialDataArray } from "types/Build/Container";



const initialState = {
  containers: containersInitialDataArray,
  is_initial: true,
};

export const ContainersSlice = createSlice({
  name: "containers",
  initialState,
  reducers: {
    storeNewContainer: (state, action) => {
      if (state.is_initial) {
        state.containers = [action.payload];
        state.is_initial = false;
      } else {
        state.containers.push(action.payload);
      }
    },
    updateContainers:(state,action)=>{
      state.containers = action.payload;
      state.is_initial = false;
    },
    clearContainers: (state) => {
        state.containers= containersInitialDataArray
    },
  },
});

export const {
  storeNewContainer,
  clearContainers,
  updateContainers
} = ContainersSlice.actions;
export default ContainersSlice.reducer;