export type Conversation = {
  id:string,
  user_one_id:string;
  user_two_id:string;
  user_one_name:string;
  user_two_name:string;
  messages: ConversationMessage[]
}

export type ConversationMessage = {
    sender_id:string,
    message:string,
    send_at:string,
    sender_name:string
}


export const conversationInitialData: Conversation = {
    id:"",
    user_one_id:"",
    user_two_id:"",
    user_one_name:"",
    user_two_name:"",
    messages: []
}


export type ReceiverConversationMessageData = {
conversation_id:string;
message: string;
send_at:string;
sender_id:string;
sender_name:string
}


export const receiverConversationMessageInitialData :ReceiverConversationMessageData = {
  conversation_id:"",
  message: "",
  send_at:"",
  sender_id:"",
  sender_name:""
  }

  export type AllowedUsers = {
    id:string,
    full_name:string
  }

  export const AllowedUsersInitialData:AllowedUsers = {
   id: "",
   full_name: "",
  }
  export const AllowedUsersInitialDataArray:AllowedUsers[] = []

export const conversationInitialDataArray: Conversation[] = [];



