export const pages: any = {
  organization_login_page: {
    description: "To create an engaging learning environment",
    form: {
      title: "Welcome, Log into your school",
      label: "Enter the School's name",
      placeholder: "School Name",
      submit_button: "Submit",
    },
  },
  login_page: {
    title: "Welcome, Log into your account",
    description: "It is our great pleasure to have you on board!",
    form: {
      email: {
        placeholder: "Enter your email address",
      },
      password: {
        placeholder: "Enter your password",
      },
      submit_button: "Submit",
    },
  },
  verify_otp: {
    title: "Verify your account",
    description: [
      "Enter the OTP code that we sent to your email.",
      "be careful not to share code with anyone",
    ],
    form: {
      timer: "Sec",
      submit_button: "Submit",
      resend_otp: {
        label: "Didn't receive the code?",
        button_text: "Request again",
      },
    },
  },
  set_password_page: {
    title: "Set Password",
    description: [
      "To protect your account, ensure your password:",
      "is longer than 7 characters.",
      "is includes at least one uppercase.",
      "is includes at least one lowercase.",
      "is includes at least one number",
      "is includes at least one special character",
    ],
    form: {
      current_password: {
        placeholder: "Current password",
      },
      password: {
        placeholder: "New password",
      },
      re_password: {
        placeholder: "Confirm password",
      },
      submit_button: "Submit",
    },
  },

  dashboard: {
    admin: {
      title: "Welcome to your dashboard",
      dashboard_functionality_list: {
        descriptions: {
          add_teacher_description:
            "Add Teachers to help you achieve your organization's goals.!",
          add_classes_description:
            "Create rich course content and coaching products for your students. When you give them a pricing plan, they’ll appear on your site!",
          add_student_description:
            "Add students to make them engage with your courses content and training products.",
        },
        title: {
          add_teacher: "Add Teachers ",
          add_classes: "Add Subjects ",
          add_student: "Add students",
        },
      },
    },
    super_admin: {
      title: "Welcome to your dashboard",

      dashboard_functionality_list: {
        descriptions: {
          add_teacher_description:
            "Add Teachers to help you achieve your organization's goals.!",
          add_classes_description:
            "Create rich course content and coaching products for your students. When you give them a pricing plan, they’ll appear on your site!",
          add_student_description:
            "Add students to make them engage with your courses content and training products.",
        },
        title: {
          add_teacher: "Add Teachers ",
          add_classes: "Add Subjects ",
          add_student: "Add students",
        },
      },
    },
    teacher: {
      title: "Welcome to your dashboard",

      messages: {
        empty_list: {
          title: "No Subjects assigned to you",
          description: "Subjects will appear here after they assigned to you .",
        },
      },
    },
    student: {
      title: "Welcome to your dashboard",
      messages: {
        empty_list: {
          title: "You are not registered in any subjects.",
          description: "Your enrolled subjects will appear here.",
        },
      },
    },
  },

  teachers: {
    title: "Teachers",
    actions: {
      add_teacher: "Add Teacher",
      export_csv: "Export CSV",
    },
    messages: {
      empty_list: {
        title: "No Teachers at this time",
        description:
          "Teachers will appear here after they enroll in your school.",
      },
    },
  },

  exams: {
    title: "Exams",
    actions: {
      add_exam: "Add Exam",
    },
    messages: {
      empty_list: {
        title: "No exams at this time",
        description: "All the exams will appear here after they are created",
      },
    },
  },
  lessons: {
    title: "Lessons",
    actions: {
      add_lesson: "Add Lesson",
    },
    messages: {
      empty_list: {
        title: "No lessons at this time",
        description: "All the lessons will appear here after they are created",
      },
    },
  },

  assignments: {
    title: "Assignments",
    actions: {
      add_assignment: "Add Assignment",
    },
    messages: {
      empty_list: {
        title: "No assignments at this time",
        description:
          "All the assignments will appear here after they are created",
      },
    },
  },

  students: {
    title: "Students",
    actions: {
      add_student: "Add Student",
      export_csv: "Export CSV",
    },
    messages: {
      empty_list: {
        title: "No Student at this time",
        description:
          "Students will appear here after they enroll in your school.",
      },
    },
  },

  subjects: {
    title: "Subjects",
    actions: {
      add_subject: "Add Subject",
    },
    messages: {
      empty_list: {
        title: "No Subjects at this time",
        description: "Subjects will appear here after when you added.",
      },
    },
  },

  messages: {
    title: "Messages",
    form: {
      title: "Start a new conversation",
      user: {
        label: "User Name",
        submit: "Start conversation",
        cancel: "Cancel",
      },
    },
    messages: {
      empty_list: {
        title: "Select a chat or start a new conversation",
      },
      empty_conversation: {
        title: "You don't have any conversations",
      },
      actions: {
        add_conversation: "Start a new conversation",
      },
    },
  },

  admins: {
    title: "Admins",
    actions: {
      add_teacher: "Add Admin",
      export_csv: "Export CSV",
    },
    messages: {
      empty_list: {
        title: "No Admins at this time",
        description:
          "Admins will appear here after they enroll in your school.",
      },
    },
  },

  add_student: {
    title: "Add Student",
    group_name: "students",
    actions: {
      manually: "Manually",
      import_csv: "Import CSV",
      add_student: "Add Student",
    },
  },

  add_subject: {
    title: "Add Subject",
    form: {
      subject_name: {
        label: "Subject Name",
      },
      teacher: {
        label: "Teacher Name",
      },
      color: {
        label: "Color",
      },
      image: {
        label: "Image",
      },
      points: {
        label: "Points",
      },
    },
    actions: {
      add_subject: "Add Subject",
    },
  },
  edit_subject: {
    title: "Edit Subject",
    form: {
      subject_name: {
        label: "Subject Name",
      },
      color: {
        label: "Color",
      },
      image: {
        label: "Image",
      },
    },
    actions: {
      edit_subject: "Edit Subject",
    },
  },

  enroll_student: {
    title: "Enroll Student",

    actions: {
      enroll_student: "Enroll Student",
    },
    messages: {
      empty_list: {
        title: "There are no students",
        description:
          "All students who are not registered for this subject will appear here.",
      },
    },
  },
  subject_students: {
    title: "Students",

    actions: {
      enroll_student: "Enroll Student",
      search:"Search for a students by name or email"
    },
    messages: {
      empty_list: {
        title: "There are no students",
        description:
          "All students who are not registered for this subject will appear here.",
      },
    },
  },

  add_teacher: {
    title: "Add Teacher",
    group_name: "teachers",
    actions: {
      manually: "Manually",
      import_csv: "Import CSV",
      add_teacher: "Add Teacher",
    },
  },
  add_admin: {
    title: "Add Admin",
    group_name: "admins",
    actions: {
      manually: "Manually",
      import_csv: "Import CSV",
      add_teacher: "Add Admin",
    },
  },

  edit_student: {
    title: "Edit Student",
    group_name: "students",
    actions: {
      edit_student: "Edit",
    },
  },
  edit_teacher: {
    title: "Edit Teacher",
    group_name: "teachers",
    actions: {
      edit_teacher: "Edit",
    },
  },

  edit_admin: {
    title: "Edit Admin",
    group_name: "admins",
    actions: {
      edit_teacher: "Edit",
    },
  },
  add_exam: {
    title: "Add Exam to ",
    form: {
      exam_deadline: {
        label: "Exam deadline",
      },
      exam_start_date: {
        label: "Exam Start Date",
      },
      exam_name: {
        label: "Exam name",
      },
      exam_mark: {
        label: "Exam Mark",
      },
      max_points: {
        label: "Max Points",
      },
      winning_points: {
        label: "Winning Points",
      },
      actions: {
        add: "Add",
      },
    },
  },
  edit_exam: {
    title: "Edit Exam to ",
    form: {
      exam_deadline: {
        label: "Exam deadline",
      },
      exam_start_date: {
        label: "Exam Start Date",
      },
      exam_name: {
        label: "Exam name",
      },
      exam_mark: {
        label: "Exam Mark",
      },
      max_points: {
        label: "Max Points",
      },
      winning_points: {
        label: "Winning Points",
      },
      actions: {
        edit: "Edit",
      },
    },
  },
  add_assignment: {
    title: "Add assignment to ",
    form: {
      assignment_start_date: {
        label: "Assignment Start Date",
      },
      assignment_deadline: {
        label: "Assignment deadline",
      },
      assignment_name: {
        label: "Assignment name",
      },
      attempts_number: {
        label: "Number of attempts",
      },
      max_points: {
        label: "Max Points",
      },
      winning_points: {
        label: "Winning Points",
      },
      actions: {
        add: "Add",
      },
    },
  },
  edit_assignment: {
    title: "Edit assignment to ",
    form: {
      assignment_start_date: {
        label: "Assignment Start Date",
      },
      assignment_deadline: {
        label: "Assignment deadline",
      },
      assignment_name: {
        label: "Assignment name",
      },
      attempts_number: {
        label: "Number of attempts",
      },
      max_points: {
        label: "Max Points",
      },
      winning_points: {
        label: "Winning Points",
      },
      actions: {
        edit: "Edit",
      },
    },
  },
  add_lesson: {
    title: "Add Lesson to ",
    form: {
      subject: {
        label: "Subject",
      },
      details: {
        label: "Details",
      },
      actions: {
        submit: "submit",
        upload_file: "upload  file",
        upload_video: "upload video",
        upload_image: "upload image",
      },
    },
  },
  edit_lesson: {
    title: "Edit Lesson  ",
    form: {
      subject: {
        label: "Subject",
      },
      details: {
        label: "Details",
      },
      actions: {
        submit: "Edit",
        upload_file: "upload  file",
        upload_video: "upload video",
        upload_image: "upload image",
      },
    },
  },
  gradeAdministration: {
    title: "Grade Administration",
    actions: {
      back_to_exams: "Back to Exams",
      back_to_assignment: "Back to Assignments",
    },
    messages: {
      empty_list: {
        title: "No Submissions yet",
        description: "All the submissions it will be appear here",
      },
    },
  },
  not_found_error_page:{
    title:"Not Found Page", 
    error_code:"Error 404",
    description:"the page you are looking for doesn't exist",
    actions:{
      back_button:"Back",
      dashboard_button:"Dashboard",
    }
  },
  server_error_page:{
      title:"Something went wrong",
      error_code:"Error 500",
      description:"sorry,Something went wrong please try again later",
      actions:{
        reload_button:"Reload the page",
        dashboard_button:"Dashboard",
      }
    
  }
};
