export type User = {
  ref_id: string;
  email: string;
  full_name: string;
  lang_code?: string;
  phone_number?: string;
  avatar?: string;
  role: string;
  gender?: string;
  status?: string;
  points?: string;
};

export type UserBasicInfo = {
  id: string;
  full_name: string;
};

export const userInitialData: User = {
  ref_id: "",
  email: "",
  full_name: "",
  lang_code: "",
  role: "",
  gender: "male",
  phone_number: "",
  status: "",
};

export const userBasicInfoInitialData: UserBasicInfo = {
  id: "",
  full_name: "",
};

export const userInitialDataArray: User[] = [];
export const userBasicInfoInitialDataArray: UserBasicInfo[] = [];
