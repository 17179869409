
import { createSlice } from "@reduxjs/toolkit";
import { otpInitialData } from "types/OTP";

const initialState = {
  otp: otpInitialData,
};

export const OTPsSlice = createSlice({
  name: "otpData",
  initialState,
  reducers: {
    setOtpData: (state, action) => {
      state.otp = action.payload;
    },
    clearOtpData: (state) => {
      state.otp = otpInitialData;
    },
    updateExpiresDate: (state, action) => {
       state.otp.expires_at = action.payload.expires_at
    },
  },
});

export const { setOtpData, clearOtpData,updateExpiresDate } = OTPsSlice.actions;
export default OTPsSlice.reducer;