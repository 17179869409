import { AssignmentContainer } from "types/Assignment/AssignmentContainer";
import { ExamContainer } from "types/Exam/ExamContainer";


export type Container  = ExamContainer | AssignmentContainer;


export const containerInitialData :Container = {
    page_number:0,
    id:"",
    content:[]
}

export const containersInitialDataArray :Container[] = [];