
import { createSlice } from "@reduxjs/toolkit";
import { PaginationInitialData } from "types/Pagination";
import { userInitialDataArray,userInitialData } from "types/User";

const initialState = {
    student: {
    is_initial_data:true,
    students:userInitialDataArray,
    selectedStudent:userInitialData,
    tablePagination:PaginationInitialData
  },
};

export const StudentSlice  = createSlice({
  name: "StudentData",
  initialState,
  reducers: {
    setStudents: (state, action) => {
      state.student.is_initial_data = false;
      state.student.students = action.payload;
    },
    setSelectedStudent: (state, action) => {
      state.student.is_initial_data = false;
      state.student.selectedStudent = action.payload;
    },
    setTablePagination: (state, action) => {
      state.student.is_initial_data = false;
      state.student.tablePagination = action.payload;
    },
  },
});

export const { setStudents,setSelectedStudent,setTablePagination } =
StudentSlice.actions;
export default StudentSlice.reducer;