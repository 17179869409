

import { createSlice } from "@reduxjs/toolkit";
import { assignmentInitialData, assignmentInitialDataArray } from "types/Assignment";
import { conversationInitialData, conversationInitialDataArray } from "types/Conversation";
import { PaginationInitialData } from "types/Pagination";

const initialState = {
    assignment: {
    is_initial_data:true,
    assignments:assignmentInitialDataArray,
    selectedAssignment:assignmentInitialData,
    tablePagination:PaginationInitialData
  },
};

export const AssignmentSlice  = createSlice({
  name: "AssignmentData",
  initialState,
  reducers: {
    storeAssignment: (state, action) => {
      state.assignment.is_initial_data = false;
      state.assignment.assignments = action.payload;
    },
    storeSelectedAssignment: (state, action) => {
      state.assignment.is_initial_data = false;
      state.assignment.selectedAssignment = action.payload;
    },
    setTablePagination: (state, action) => {
      state.assignment.is_initial_data = false;
      state.assignment.tablePagination = action.payload;
    },

  },
});

export const { storeAssignment,storeSelectedAssignment,setTablePagination } =
AssignmentSlice.actions;
export default AssignmentSlice.reducer;