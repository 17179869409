import { PageRolesList } from "assets/data/PageRolesList";
import { getStoredUserData } from "./localStorage-service";

export const CheckAllowedToAccess = (pageName: string) => {
  const pageDetails = PageRolesList.find((page) => {
    return page.name == pageName;
  });
  if (pageDetails) {
    let isAllowedToAccess = pageDetails.allowedRole.find((role) => {
      return role == getStoredUserData()?.role;
    });
    if (isAllowedToAccess) {
      return true;
    }
    return false;
  }
  return false;
};
