


import profile from "api/ClientPortal/profile";
import { AxiosError, AxiosResponse, isAxiosError } from "axios";
import Preloader from "components/global/Preloader";
import { ErrorCodesEnum } from "enums/ErrorCodesEnum";
import React from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { clearAllLocalStorage, getToken, storageUserData } from "services/localStorage-service";

const ProtectedRoute = ({ children }: any) => {
  const dispatch = useDispatch();
  const { isLoading, error, data } = useQuery("user", () => {
    return profile.loadUserBasicInfo();
  });
  if (getToken() != null) {

    if (isLoading) {
      return <Preloader />;
    }

   
    if (error) {
   
      if (isAxiosError(error)) {
        if (
          error.code === ErrorCodesEnum.ERR_NETWORK ||
          error.code === ErrorCodesEnum.ERR_CANCELED
        ) {
          clearAllLocalStorage();
           <Navigate to="/" replace />;
        } else {
         
          switch (error.response?.status) {
            case 401:
              clearAllLocalStorage();
               <Navigate to="/" replace />;  
               break;
            case 500:
              clearAllLocalStorage();
               <Navigate to="/" replace />;
          }
        }
      }
     <Navigate to="/dashboard" replace />;
    }else{
      let response = data as AxiosResponse;

      switch (response.data.code) {
   
        case 200:
          storageUserData(response.data.data);
  
          return children;
      }
    }

    
  }
  return <Navigate to="/auth/login" replace />;
};

export default ProtectedRoute;
