
import { lazy } from "react";
import {  RouteObject } from "react-router-dom";
import ProtectedRoute from "routes/middleware/ProtectedRoute";



// const Login = lazy(() => import("./pages/error/NotFoundPage"));
const Messages = lazy(() => import("pages/message/MessagesPage")); 

const MessageRoutes = (): RouteObject[] => {
    const routes: RouteObject[] = [
      {
        path: "",
        element: <ProtectedRoute> <Messages/>  </ProtectedRoute> ,
      },


    ];
  
    return routes;
  };
  export default MessageRoutes;