import LoginPage from "../../pages/auth/LoginPage";
import VerifyOTPsPages from "../../pages/auth/VerifyOTPsPages";
import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import { PublicRoutes } from "../middleware/PublicRoutes";
import ProtectedRoute from "routes/middleware/ProtectedRoute";

const Dashboard = lazy(() => import("pages/Dashboard"));
// const VerifyOTPs = lazy(() => import("@/pages/auth/VerifyOTPsPages"));
const Routes = (): RouteObject[] => {
  const routes: RouteObject[] = [
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ),
    },
  ];

  return routes;
};
export default Routes;
