
import { configureStore } from "@reduxjs/toolkit";
import AppSettingsReducer from "./slice/AppSettingsSlice"
import OTPsReducer from "./slice/OTPsSlice";
import AdminReducer from "./slice/AdminSlice";
import StudentReducer from "./slice/StudentSlice";
import TeacherReducer from "./slice/TeacherSlice";
import ConversationReducer from "./slice/ConversationSlice"
import SubjectReducer from "./slice/SubjectSlice";
import ExamReduce from "./slice/ExamSlice";
import AssignmentReduce from "./slice/AssignmentSlice";
import ContainersReduce from "./slice/Build/ContainersSlice";
import SetPasswordReduce from "./slice/SetPasswordSlice";
import LessonSliceReduce from "./slice/LessonSlice";
export const store = configureStore({
reducer:{
    AppSettingsReducer,
    OTPsReducer,
    AdminReducer,
    StudentReducer,
    TeacherReducer,
    ConversationReducer,
    SubjectReducer,
    ExamReduce,
    AssignmentReduce,
    ContainersReduce,
    SetPasswordReduce,
    LessonSliceReduce

},
devTools: process.env.REACT_APP_ADMIN_PORTAL_BACKEND_URL != "local",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;