import { RoleEnum } from "enums/RoleEnum";
import { PageRoles } from "types/PageRoles";

export const PageRolesList: PageRoles[] = [
  {
    id: (Math.random() * 100).toString(36),
    name: "Students",
    allowedRole: [RoleEnum.Admin, RoleEnum.Super_admin],
  },
  {
    id: (Math.random() * 100).toString(36),
    name: "Subjects",
    allowedRole: [RoleEnum.Admin, RoleEnum.Super_admin],
  },

  {
    id: (Math.random() * 100).toString(36),
    name: "Teachers",
    allowedRole: [RoleEnum.Admin, RoleEnum.Super_admin],
  },
  {
    id: (Math.random() * 100).toString(36),
    name: "Admins",
    allowedRole: [RoleEnum.Super_admin],
  },
  {
    id: (Math.random() * 100).toString(36),
    name: "Subjects_Details",
    allowedRole: [RoleEnum.Teacher],
  },
  {
    id: (Math.random() * 100).toString(36),
    name: "Subject_Students",
    allowedRole: [RoleEnum.Teacher],
  }

  

];
