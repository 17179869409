export const lists: any = {

gender:{
    male:"Male",
    female:"Female"
},
status:{
    active:"Active",
    inactive:"Inactive",
    pending:"Pending"
},
time:{
    am:"AM",
    pm:"PM"
  },
  element_list:{
    "short_answer":"Short Answer",
    "long_answer":"Long Answer",
    "multiple_choice":"Multiple Choice",
    "content":"Content"
  },
}